import logo from "./tianguis.png";
import "./App.css";
import styled from "styled-components";

const Container = styled.div`
   {
    display: flex;
    padding: 32px;
  }
`;

const Button = styled.button`
   {
    width: 180px;
    padding: 0px 8px;
    margin: 16px;
    font-size: 32px;
    color: black;
    background-color: yellow;
    border-radius: 8px;
  }
`;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://app.tianguis.finance"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        {/* <Container>
        <a
          className="App-link"
          href="https://lamiscelanea.tianguis.finance"
          target="_blank"
          rel="noopener noreferrer"
        >
        
      <Button>Tienda</Button>
        </a>
        <a
          className="App-link"
          href="https://app.tianguis.finance"
          target="_blank"
          rel="noopener noreferrer"
        >
      <Button>DeFi</Button>
        </a>
</Container>
 */}
      </header>
    </div>
  );
}

export default App;
